import { Middleware } from "@reduxjs/toolkit";

import { useEffect } from "react";
import { useRouter } from "next/router";

//import { useAppDispatch } from "@/src/hooks/useDispatch";
import { getBookmarks } from "./slices/bookmarkSlice";
import { userLogout } from "./slices/userSlice";
import { organizerLogin } from "./slices/organizerSlice";
// import { getUserSettings } from "./slices/settingsSlice";
//import store from "./store";

// Custom middleware
export const loginMiddleware: Middleware<{}, any, any> = (store) => (next) => (action) => {
    
    // Get the current state
    const currentState = store.getState();
    // // Dispatch the action
    const result = next(action);
    // // Get the updated state
    const nextState = store.getState();

    // check if previous state is equal to next state
    // check if token exists in user
    if (currentState.user.data.id !== nextState.user.data.id) {
        const {token, is_organizer} = nextState.user.data

        // if token exists: login 
        // if token doesnt exist : logout
        if (token) {
            // Your code to run whenever the "user" state changes
            store.dispatch(getBookmarks())
            // store.dispatch(getUserSettings())
            // get user settings
            // get organizer
            if (is_organizer) {
                store.dispatch(organizerLogin())
            }
        }

    // Continue with the action
    return result;
    };
}