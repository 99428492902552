import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';

import axios from 'axios';


/** ASYNC THUNKS */
export const organizerLogin = createAsyncThunk(
  'organizer/organizerLogin',
  async (_, {getState}) => {

    //get state to access token
    const ReduxState:any = getState();
    const userData = ReduxState.user.data
    const {token} = userData

    if (token) {
      const config = { headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }}
      //get env var
      const res = await fetch('/api/env/backend-url')
      const {backendUrl} = await res.json();
      if (!backendUrl) {
        console.error('Backend URL env is missing');
        return ReduxState.organizer
      }
      // send axios request
      const response = await axios.get(
        `${backendUrl}/api/organizer/organizer-login/`,
        config
      )
      const {status, data} = response
      if (status==200) {
        return data
      }  else {
        console.error('error organizer login', data)
      }
    }
  }
);


/** SLICE SETTINGS */

interface OrganizerState {
  data: {
    organizerId: string;
    is_organization: boolean;
    has_instructors: boolean;
    has_lessons: boolean;
    has_membership: boolean;
  };
  status: 'idle' | 'succeeded' | 'loading' | 'failed';
  error: string | null;
}

const initialState: OrganizerState = {
  data: {
    organizerId: '',
    is_organization: false,
    has_instructors: false,
    has_lessons: false,
    has_membership: false,
  },
  status: 'idle',
  error: null
};


export const organizerSlice = createSlice({
  name: 'organizer',
  initialState,
  reducers: {
    updateOrganizerProfile: (state, action) => {
      state.data = action.payload
    },
    organizerLogout: (state) => {
      state.data = initialState.data
    }
  },
  extraReducers: (builder) => {
    //organizer login
    builder.addCase(organizerLogin.pending, (state) => {
      state.status = 'loading'
      state.data = initialState.data
    });
    builder.addCase(organizerLogin.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    });
    builder.addCase(organizerLogin.rejected, (state, action) => {
      state.status = 'failed'
      state.data = initialState.data
    })

  }
});

//reducers
export const {
  updateOrganizerProfile, organizerLogout
} = organizerSlice.actions;

export default organizerSlice.reducer;

export const selectOrganizer = (state:RootState) => state.organizer || initialState;

