// store/slices/cartSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';


//types
import { PublicLessonProfileType } from "@/src/types/lesson";
import { StudentDataType } from '@/src/types-v3/user';
import { MonthlyPlanType, TicketPlanType } from '@/src/types-v3/membership';
import { MonthlyPassType, TicketPassType } from '@/src/types-v3/membership';

interface CartState {
  lessonClassData: PublicLessonProfileType | null;
  studentData: StudentDataType | null;

  passData: null | 
    {type:'monthly', pass: MonthlyPassType} | 
    {type:'ticket', pass: TicketPassType};
  planData: null | 
    {type:'monthly', plan: MonthlyPlanType} | 
    {type:'ticket', plan: TicketPlanType} | 
    {type:'lesson', plan: null};
}

const initialState: CartState = {
  lessonClassData: null,
  studentData: null,
  passData: null,
  planData: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLessonClassData: (state, action) => {
      state.lessonClassData = action.payload;
    },
    setStudentData: (state, action) => {
      state.studentData = action.payload;
    },
    setPassData: (state, action) => {
      state.passData = action.payload;
    },
    setPlanData: (state, action) => {
      state.planData = action.payload;
    },
    resetCart: (state) => {
      state.lessonClassData = null;
      state.studentData = null;
      state.passData = null;
      state.planData = null;
    }
  },
});

export const { 
  setLessonClassData, 
  setStudentData, 
  setPassData,
  setPlanData,
  resetCart
} = cartSlice.actions;

export default cartSlice.reducer;

export const selectCart = (state: RootState) => state.cart || initialState;
